/** @jsx jsx */
import { Container, Row, Col } from "react-bootstrap";
// import Paginations from "@/components/paginations";
import BlogCard from "@/components/blog-card";

import { jsx } from "@emotion/react";
import {commonSection} from "../assets/styles/layout.styles";

import { 
  titleBlog
}  from '../assets/styles/Blog.styles'

const BlogOne = ({posts, url}) => {

  return (
    <section css={commonSection} className="blogPage">
      <Container>
        <Row>
          <Col lg={12} sm={12} md={12}><div css={titleBlog}>NOSSO BLOG</div></Col>
        </Row>
        <Row>
          {posts.map((post, index) => (
            <Col lg={12} md={12} sm={12}  key={index}>
              <BlogCard url={url} data={post} />
            </Col>
          ))}
        </Row>
        {/* <Paginations /> */}
      </Container>
    </section>
  );
};

export default BlogOne;
